import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../components/seo";
import Skel from "../components/skel";
import { SIGNUP_URL, BRAND_NAME } from "../util/constants";
import "./index.scss";
import "./homebrew.scss";

const HomebrewPage = () => {
  const data = useStaticQuery(graphql`
    query {
      homebrewWoodWall: file(
        relativePath: { eq: "mockups/wood-wall-menu-cropped.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      webMenu1: file(
        relativePath: { eq: "screenshots/homebrew-web-menu.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      sampleGenericMenu: file(relativePath: { eq: "sample-generic-menu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      tapsOnMac: file(relativePath: { eq: "mockups/taps-on-mac.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pairingTv: file(relativePath: { eq: "pairing-tv.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pairingPaired: file(relativePath: { eq: "pairing-paired.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      fireStick: file(relativePath: { eq: "fire-stick.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      brewersfriend: file(
        relativePath: { eq: "integrations/brewers-friend.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      brewfather: file(relativePath: { eq: "integrations/brewfather.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      plaato: file(relativePath: { eq: "integrations/plaato.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Skel fixedHeader>
      <SEO title="Digital beer menu chalkboard for Homebrewers" />
      <main>
        <section id="home" className="gray-bg overflow-hidden">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6 m-50px-tb">
                <h1 className="dark-color display-4 m-20px-b">
                  Taplist.io: For Homebrewers<br/>
                  <small>Pro-quality Menu System</small>
                </h1>
                <p className="font-2">
                  Give your setup a professional-grade TV and Web menu, at a
                  special homebrewers-only price.
                </p>
                <div className="p-10px-t">
                  <a
                    className="m-btn m-btn-theme m-btn-radius"
                    href={SIGNUP_URL}
                  >
                    Start Now
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <Img
                  className="max-width-auto"
                  fluid={data.homebrewWoodWall.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={false}
                  alt="Brewpub menu on a TV"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="section effect-section">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h3 className="h1 m-15px-b">Use What The Pros Use</h3>
                <p className="m-0px font-2">
                  Taplist.io is trusted by hundreds of bars, breweries, and
                  brewpubs. Because our roots are in craft brewing, we're
                  bringing the same system to you.
                </p>
              </Col>
            </Row>

            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={8}>
                <Img
                  fluid={data.tapsOnMac.childImageSharp.fluid}
                  loading="eager"
                  alt="A beverage menu web page"
                />
              </Col>
              <Col lg={4}>
                <h2 className="m-20px-b">Fun &amp; easy</h2>
                {/* <p className="m-10px-b font-2">
                  Using Taplist.io is simple.
                </p> */}
                <ul className="list-type-03">
                  <li>
                    <i className="fas fa-check"></i> Create your taps and assign
                    kegs to them.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Edit and manage beverages
                    in your own private beverage database.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Updates appear in realtime
                    on your web and TV menus.
                  </li>
                </ul>
                <p>
                  See why <b>thousands of people</b> have chosen Taplist.io as
                  the <b>fastest, easiest to customize</b> menu choice.
                </p>
                <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius"
                    href="https://app.taplist.io/signup"
                    target="_blank"
                  >
                    Get Started
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="integrations" className="section gray-bg">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h3 className="h1 m-15px-b">Awesome Integrations</h3>
                <p className="m-0px font-2">
                  Taplist.io is designed to work with all your favorite homebrew
                  tools, and we're growing every day.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 2, offset: 2 }}>
                <div class="box-shadow-hover hover-top white-bg border-radius-3">
                  <div class="p-10px">
                    <Img
                      className="max-width-auto"
                      fluid={data.plaato.childImageSharp.fluid}
                      alt="Logo for Plaato"
                    />
                  </div>
                  <div class="p-5px-t p-20px-b text-center">
                    <h6 class="m-5px-b">Plaato Keg</h6>
                    <small>Level monitoring</small>
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 2, offset: 1 }}>
                <div class="box-shadow-hover hover-top white-bg border-radius-3">
                  <div class="p-10px">
                    <Img
                      className="max-width-auto"
                      fluid={data.brewersfriend.childImageSharp.fluid}
                      alt="Logo for Brewer's Friend"
                    />
                  </div>
                  <div class="p-5px-t p-20px-b text-center">
                    <h6 class="m-5px-b">Brewer's Friend</h6>
                    <small>Import &amp; sync</small>
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 2, offset: 1 }}>
                <div class="box-shadow-hover hover-top white-bg border-radius-3">
                  <div class="p-10px">
                    <Img
                      className="max-width-auto"
                      fluid={data.brewfather.childImageSharp.fluid}
                      alt="Logo for Brewfather"
                    />
                  </div>
                  <div class="p-5px-t p-20px-b text-center">
                    <h6 class="m-5px-b">Brewfather</h6>
                    <small>Import &amp; sync</small>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="web-menus" className="section">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={6}>
                <h3 className="h1 m-15px-b">
                  Focus On Your Brews
                  <br />
                  <small>Leave The Menu To Us</small>
                </h3>
                <p className="m-10px-b font-2 font-w-600">
                  For less than the cost of a cloud webserver, you get a fully
                  managed, automatically-updating, professional menu system.
                </p>
                <ul className="list-type-03">
                  <li>
                    <i className="fas fa-check"></i> Lightning-fast, 24/7
                    internet hosting. It's all built in.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Shareable, HTTPS web
                    address that's always online.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Remote dashboard where you
                    can make changes from anywhere.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Tons of built-in
                    illustrations and customizations.
                  </li>
                </ul>
                <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius"
                    href="https://taplist.io/brewspot"
                    target="_blank"
                  >
                    See an Example
                  </a>
                </p>
              </Col>
              <Col lg={6}>
                <Img
                  fluid={data.webMenu1.childImageSharp.fluid}
                  alt="A beverage menu web page"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="features" className="section gray-bg">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h3 className="h1 m-15px-b">A Homebrewers-Only Deal</h3>
                <p className="m-0px font-2">
                  They said we were crazy to sell to hobby users, but we all
                  start somewhere, and we love homebrewing. That's why we're
                  giving you a <b>massive discount</b> on the same system
                  the pros use. It's all yours for only...
                </p>
              </Col>
            </Row>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h5>Normally: <s>$399/year</s></h5>
                <h2>$39/year</h2>
              </Col>
            </Row>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius btn-lg"
                    href="https://app.taplist.io/signup"
                    target="_blank"
                  >
                    Get Started Now
                  </a>
                </p>

                <p>
                  <small>
                    The Fine Print: No commercial use, this discount is for homebrewers
                    only.
                    <br />
                    If you're retailing or selling anything, we'll ask you to upgrade or close your account.
                    <br/>
                    See full capabilities on our <Link to="/pricing">pricing page</Link>.
                  </small>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Skel>
  );
};

export default HomebrewPage;
